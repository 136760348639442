import { XMarkIcon } from '@heroicons/react/24/outline'
import * as Radix from '@radix-ui/react-dialog'
import cx from 'classnames'
import type { ReactNode } from 'react'

export interface DialogProps {
  trigger: ReactNode
  title: string
  content: ReactNode
  className?: string
  isOpen?: boolean
  onOpenChange?: (open: boolean) => void
  isDisabled?: boolean
  position?: 'bottom' | 'central' | 'left'
}

const Dialog = ({
  trigger,
  title,
  content,
  className = 'top-1/3',
  isOpen,
  onOpenChange,
  isDisabled,
  position = 'bottom'
}: DialogProps) => (
  <Radix.Root open={isOpen} onOpenChange={onOpenChange}>
    <Radix.Trigger asChild disabled={isDisabled}>
      {trigger}
    </Radix.Trigger>
    <Radix.Portal>
      <Radix.Overlay className="fixed inset-0 z-40 bg-black/60" />
      <Radix.Content
        className={cx(
          'dialog fixed z-50 flex flex-col overflow-y-hidden bg-white text-dark shadow-2xl dark:bg-grey dark:text-white',
          position === 'bottom' &&
            'radix-modal-long-animation bottom-0 left-0 right-0  max-h-almost-full w-screen  rounded-t-2xl ',
          position === 'left' && 'radix-modal-right-animation bottom-0 left-0 top-0  w-4/5  rounded-r-2xl ',
          position === 'central' &&
            'inset-0 max-w-screen sm:inset-auto sm:left-1/2 sm:top-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2 sm:rounded-2xl',
          className
        )}>
        <div className="relative py-4 pl-6 pr-16 shadow-md">
          <Radix.Title className="font-copy">{title}</Radix.Title>
          <Radix.Close asChild className="absolute right-4 top-4">
            <button aria-label="Close" className="rounded-full p-2 hover:bg-black/10 dark:hover:bg-white/20">
              <XMarkIcon className="h-5" />
            </button>
          </Radix.Close>
        </div>
        <div className="flex-1 overflow-y-auto p-6">{content}</div>
      </Radix.Content>
    </Radix.Portal>
  </Radix.Root>
)

export default Dialog
